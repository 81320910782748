<template>
  <el-result icon="warning" title="404" sub-title="抱歉,您访问的资源不存在！">
    <template #extra>
      <el-button type="primary" @click="toHome">
        返回首页
      </el-button>
    </template>
  </el-result>
</template>

<script>
export default {
  name: 'Exception404',
  methods: {
    toHome () {
      this.$router.push({ path: '/home' })
    }
  }
}
</script>
